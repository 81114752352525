import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { getAffiliate } from "../../../helpers/affiliate";
import jwt from "../../../helpers/jwt";
import { track } from "../../../helpers/track";
import { getFirmographic, updateMetadata } from "../../../helpers/zipps";
import creditsIdGet from "../../../services/creditsIdGet";
import signupPost from "../../../services/signupPost";
import { setToken } from "../../../store/security/actions";
import SignupForm, { FormValues } from "../components/SignupForm";

export interface Props extends RouteComponentProps {
  setToken: (token: string) => void;
}

class SignupContainer extends React.Component<Props> {
  onSubmit = async (formData: FormValues) => {
    try {
      const affiliate = getAffiliate();
      const result = await signupPost({ ...formData, affiliate });
      if (result.payload && result.payload.token) {
        this.props.setToken(result.payload.token);
        const { sub } = jwt.decode(result.payload.token);
        await updateMetadata({ externalId: sub });
        const firmographic = await getFirmographic();
        const properties = {
          ...affiliate,
          date: +new Date,
          registryCity: firmographic.registry_city,
        };
        track({ userId: sub, event: "SIGNUP", properties });
        const querystring = window.location.search;
        const params = new URLSearchParams(querystring);
        const offerId = params.get("ide");
        if (offerId !== null) {
          creditsIdGet({ offerId });
        }
        this.props.history.push("/planes");
        // this.props.history.push("/dashboard");
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return <SignupForm onSubmit={this.onSubmit} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setToken: (token: string) => dispatch(setToken(token)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter<RouteComponentProps>(SignupContainer));
