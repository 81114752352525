import { Affiliate } from "../types/Affiliate";

export const getAffiliate = () => {
  try {
    const affiliateData = localStorage.getItem("affiliate");
    return affiliateData ? (JSON.parse(affiliateData) as Affiliate) : {};
  } catch (error) {
    return {};
  }
};
