import { Affiliate } from "../types/Affiliate";
import { ServiceReturn } from "../types/ServiceReturn";
import api from "./api";

export interface LoginParams {
  documentId: string;
  password: string;
  code: string;
  affiliate?: Affiliate;
}

export interface LoginResponse {
  token: string;
}

export const url = "login";

const loginPost = async (
  postData: LoginParams
): Promise<ServiceReturn<LoginResponse>> => {
  const result = await api.post(url, false, { ...postData });
  return Promise.resolve(result);
};

export default loginPost;
