import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import { getAffiliate } from "../../../helpers/affiliate";
import jwt from "../../../helpers/jwt";
import { track } from "../../../helpers/track";
import uuid from "../../../helpers/uuid";
import { getFirmographic, updateMetadata } from "../../../helpers/zipps";
import logInPost from "../../../services/loginPost";
import { setToken } from "../../../store/security/actions";
import LogInForm, { FormValues } from "../components/LogInForm";

export interface Props extends RouteComponentProps {
  setToken: (token: string) => void;
}

class LogInContainer extends React.Component<Props> {
  handleSubmit = async (formData: FormValues) => {
    try {
      const affiliate = getAffiliate();
      const response = await logInPost({ ...formData, affiliate });
      if (response && response.payload && response.payload.token) {
        const token = response.payload.token;
        if (localStorage) {
          localStorage.setItem("_nbc_id_", formData.documentId)
        }
        this.props.setToken(token);
        const { sub } = jwt.decode(token);
        uuid.set(sub);
        await updateMetadata({ externalId: sub });
        const firmographic = await getFirmographic();
        const properties = {
          ...affiliate,
          date: +new Date,
          registryCity: firmographic.registry_city,
        };
        track({ userId: sub, event: "LOGIN", properties });
        const { from } = this.props.location.state || {
          from: { pathname: "/dashboard" }
        };
        this.props.history.push(from);
        document.cookie =
          "uuid=" +
          sub +
          ";domain=" +
          window.location.hostname.replace("play.", "").replace("app.", "");
      }
    } catch (error) {
      throw error;
    }
  };

  render() {
    return <LogInForm onSubmit={this.handleSubmit} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setToken: (token: string) => dispatch(setToken(token))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(LogInContainer));
